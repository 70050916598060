// This file is auto generated by the protocol-buffers compiler

/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */

// Remember to `npm install --save protocol-buffers-encodings`
var encodings = require('protocol-buffers-encodings')
var varint = encodings.varint
var skip = encodings.skip

exports.PartType = {
  Beam: 0,
  Skin: 1,
  Fastener: 2,
  Accessory: 3,
  Adapter: 4
}

exports.AxisDirection = {
  "X": 0,
  "-X": 1,
  "Y": 2,
  "-Y": 3,
  "Z": 4,
  "-Z": 5
}

exports.SpecId = {
  og: 0
}

exports.SizeId = {
  "1.5in": 0,
  "1in": 1,
  "2in": 2,
  "25mm": 3,
  "40mm": 4,
  "50mm": 5
}

exports.MaterialId = {
  Wood: 0,
  Aluminum: 1,
  Steel: 2
}

var Direction = exports.Direction = {
  buffer: true,
  encodingLength: null,
  encode: null,
  decode: null
}

var GridPosition = exports.GridPosition = {
  buffer: true,
  encodingLength: null,
  encode: null,
  decode: null
}

var Part = exports.Part = {
  buffer: true,
  encodingLength: null,
  encode: null,
  decode: null
}

var Model = exports.Model = {
  buffer: true,
  encodingLength: null,
  encode: null,
  decode: null
}

defineDirection()
defineGridPosition()
definePart()
defineModel()

function defineDirection () {
  Direction.encodingLength = encodingLength
  Direction.encode = encode
  Direction.decode = decode

  function encodingLength (obj) {
    var length = 0
    if (defined(obj.x)) {
      var len = encodings.float.encodingLength(obj.x)
      length += 1 + len
    }
    if (defined(obj.y)) {
      var len = encodings.float.encodingLength(obj.y)
      length += 1 + len
    }
    if (defined(obj.z)) {
      var len = encodings.float.encodingLength(obj.z)
      length += 1 + len
    }
    return length
  }

  function encode (obj, buf, offset) {
    if (!offset) offset = 0
    if (!buf) buf = Buffer.allocUnsafe(encodingLength(obj))
    var oldOffset = offset
    if (defined(obj.x)) {
      buf[offset++] = 13
      encodings.float.encode(obj.x, buf, offset)
      offset += encodings.float.encode.bytes
    }
    if (defined(obj.y)) {
      buf[offset++] = 21
      encodings.float.encode(obj.y, buf, offset)
      offset += encodings.float.encode.bytes
    }
    if (defined(obj.z)) {
      buf[offset++] = 29
      encodings.float.encode(obj.z, buf, offset)
      offset += encodings.float.encode.bytes
    }
    encode.bytes = offset - oldOffset
    return buf
  }

  function decode (buf, offset, end) {
    if (!offset) offset = 0
    if (!end) end = buf.length
    if (!(end <= buf.length && offset <= buf.length)) throw new Error("Decoded message is not valid")
    var oldOffset = offset
    var obj = {
      x: 0,
      y: 0,
      z: 0
    }
    while (true) {
      if (end <= offset) {
        decode.bytes = offset - oldOffset
        return obj
      }
      var prefix = varint.decode(buf, offset)
      offset += varint.decode.bytes
      var tag = prefix >> 3
      switch (tag) {
        case 1:
        obj.x = encodings.float.decode(buf, offset)
        offset += encodings.float.decode.bytes
        break
        case 2:
        obj.y = encodings.float.decode(buf, offset)
        offset += encodings.float.decode.bytes
        break
        case 3:
        obj.z = encodings.float.decode(buf, offset)
        offset += encodings.float.decode.bytes
        break
        default:
        offset = skip(prefix & 7, buf, offset)
      }
    }
  }
}

function defineGridPosition () {
  GridPosition.encodingLength = encodingLength
  GridPosition.encode = encode
  GridPosition.decode = decode

  function encodingLength (obj) {
    var length = 0
    if (defined(obj.x)) {
      var len = encodings.sint64.encodingLength(obj.x)
      length += 1 + len
    }
    if (defined(obj.y)) {
      var len = encodings.sint64.encodingLength(obj.y)
      length += 1 + len
    }
    if (defined(obj.z)) {
      var len = encodings.sint64.encodingLength(obj.z)
      length += 1 + len
    }
    return length
  }

  function encode (obj, buf, offset) {
    if (!offset) offset = 0
    if (!buf) buf = Buffer.allocUnsafe(encodingLength(obj))
    var oldOffset = offset
    if (defined(obj.x)) {
      buf[offset++] = 8
      encodings.sint64.encode(obj.x, buf, offset)
      offset += encodings.sint64.encode.bytes
    }
    if (defined(obj.y)) {
      buf[offset++] = 16
      encodings.sint64.encode(obj.y, buf, offset)
      offset += encodings.sint64.encode.bytes
    }
    if (defined(obj.z)) {
      buf[offset++] = 24
      encodings.sint64.encode(obj.z, buf, offset)
      offset += encodings.sint64.encode.bytes
    }
    encode.bytes = offset - oldOffset
    return buf
  }

  function decode (buf, offset, end) {
    if (!offset) offset = 0
    if (!end) end = buf.length
    if (!(end <= buf.length && offset <= buf.length)) throw new Error("Decoded message is not valid")
    var oldOffset = offset
    var obj = {
      x: 0,
      y: 0,
      z: 0
    }
    while (true) {
      if (end <= offset) {
        decode.bytes = offset - oldOffset
        return obj
      }
      var prefix = varint.decode(buf, offset)
      offset += varint.decode.bytes
      var tag = prefix >> 3
      switch (tag) {
        case 1:
        obj.x = encodings.sint64.decode(buf, offset)
        offset += encodings.sint64.decode.bytes
        break
        case 2:
        obj.y = encodings.sint64.decode(buf, offset)
        offset += encodings.sint64.decode.bytes
        break
        case 3:
        obj.z = encodings.sint64.decode(buf, offset)
        offset += encodings.sint64.decode.bytes
        break
        default:
        offset = skip(prefix & 7, buf, offset)
      }
    }
  }
}

function definePart () {
  Part.encodingLength = encodingLength
  Part.encode = encode
  Part.decode = decode

  function encodingLength (obj) {
    var length = 0
    if ((+defined(obj.direction) + +defined(obj.axisDirection)) > 1) throw new Error("only one of the properties defined in oneof direction_oneof can be set")
    if (!defined(obj.type)) throw new Error("type is required")
    var len = encodings.enum.encodingLength(obj.type)
    length += 1 + len
    if (defined(obj.origin)) {
      var len = GridPosition.encodingLength(obj.origin)
      length += varint.encodingLength(len)
      length += 1 + len
    }
    if (defined(obj.sizeId)) {
      var len = encodings.enum.encodingLength(obj.sizeId)
      length += 1 + len
    }
    if (defined(obj.materialId)) {
      var len = encodings.enum.encodingLength(obj.materialId)
      length += 1 + len
    }
    if (defined(obj.direction)) {
      var len = Direction.encodingLength(obj.direction)
      length += varint.encodingLength(len)
      length += 1 + len
    }
    if (defined(obj.axisDirection)) {
      var len = encodings.enum.encodingLength(obj.axisDirection)
      length += 1 + len
    }
    if (defined(obj.length)) {
      var len = encodings.varint.encodingLength(obj.length)
      length += 1 + len
    }
    return length
  }

  function encode (obj, buf, offset) {
    if (!offset) offset = 0
    if (!buf) buf = Buffer.allocUnsafe(encodingLength(obj))
    var oldOffset = offset
    if ((+defined(obj.direction) + +defined(obj.axisDirection)) > 1) throw new Error("only one of the properties defined in oneof direction_oneof can be set")
    if (!defined(obj.type)) throw new Error("type is required")
    buf[offset++] = 8
    encodings.enum.encode(obj.type, buf, offset)
    offset += encodings.enum.encode.bytes
    if (defined(obj.origin)) {
      buf[offset++] = 18
      varint.encode(GridPosition.encodingLength(obj.origin), buf, offset)
      offset += varint.encode.bytes
      GridPosition.encode(obj.origin, buf, offset)
      offset += GridPosition.encode.bytes
    }
    if (defined(obj.sizeId)) {
      buf[offset++] = 24
      encodings.enum.encode(obj.sizeId, buf, offset)
      offset += encodings.enum.encode.bytes
    }
    if (defined(obj.materialId)) {
      buf[offset++] = 32
      encodings.enum.encode(obj.materialId, buf, offset)
      offset += encodings.enum.encode.bytes
    }
    if (defined(obj.direction)) {
      buf[offset++] = 42
      varint.encode(Direction.encodingLength(obj.direction), buf, offset)
      offset += varint.encode.bytes
      Direction.encode(obj.direction, buf, offset)
      offset += Direction.encode.bytes
    }
    if (defined(obj.axisDirection)) {
      buf[offset++] = 48
      encodings.enum.encode(obj.axisDirection, buf, offset)
      offset += encodings.enum.encode.bytes
    }
    if (defined(obj.length)) {
      buf[offset++] = 56
      encodings.varint.encode(obj.length, buf, offset)
      offset += encodings.varint.encode.bytes
    }
    encode.bytes = offset - oldOffset
    return buf
  }

  function decode (buf, offset, end) {
    if (!offset) offset = 0
    if (!end) end = buf.length
    if (!(end <= buf.length && offset <= buf.length)) throw new Error("Decoded message is not valid")
    var oldOffset = offset
    var obj = {
      type: 0,
      origin: null,
      sizeId: 0,
      materialId: 0,
      direction: null,
      axisDirection: 0,
      length: 0
    }
    var found0 = false
    while (true) {
      if (end <= offset) {
        if (!found0) throw new Error("Decoded message is not valid")
        decode.bytes = offset - oldOffset
        return obj
      }
      var prefix = varint.decode(buf, offset)
      offset += varint.decode.bytes
      var tag = prefix >> 3
      switch (tag) {
        case 1:
        obj.type = encodings.enum.decode(buf, offset)
        offset += encodings.enum.decode.bytes
        found0 = true
        break
        case 2:
        var len = varint.decode(buf, offset)
        offset += varint.decode.bytes
        obj.origin = GridPosition.decode(buf, offset, offset + len)
        offset += GridPosition.decode.bytes
        break
        case 3:
        obj.sizeId = encodings.enum.decode(buf, offset)
        offset += encodings.enum.decode.bytes
        break
        case 4:
        obj.materialId = encodings.enum.decode(buf, offset)
        offset += encodings.enum.decode.bytes
        break
        case 5:
        delete obj.axisDirection
        var len = varint.decode(buf, offset)
        offset += varint.decode.bytes
        obj.direction = Direction.decode(buf, offset, offset + len)
        offset += Direction.decode.bytes
        break
        case 6:
        delete obj.direction
        obj.axisDirection = encodings.enum.decode(buf, offset)
        offset += encodings.enum.decode.bytes
        break
        case 7:
        obj.length = encodings.varint.decode(buf, offset)
        offset += encodings.varint.decode.bytes
        break
        default:
        offset = skip(prefix & 7, buf, offset)
      }
    }
  }
}

function defineModel () {
  Model.encodingLength = encodingLength
  Model.encode = encode
  Model.decode = decode

  function encodingLength (obj) {
    var length = 0
    if (defined(obj.parts)) {
      for (var i = 0; i < obj.parts.length; i++) {
        if (!defined(obj.parts[i])) continue
        var len = Part.encodingLength(obj.parts[i])
        length += varint.encodingLength(len)
        length += 1 + len
      }
    }
    if (defined(obj.specId)) {
      var len = encodings.enum.encodingLength(obj.specId)
      length += 1 + len
    }
    return length
  }

  function encode (obj, buf, offset) {
    if (!offset) offset = 0
    if (!buf) buf = Buffer.allocUnsafe(encodingLength(obj))
    var oldOffset = offset
    if (defined(obj.parts)) {
      for (var i = 0; i < obj.parts.length; i++) {
        if (!defined(obj.parts[i])) continue
        buf[offset++] = 10
        varint.encode(Part.encodingLength(obj.parts[i]), buf, offset)
        offset += varint.encode.bytes
        Part.encode(obj.parts[i], buf, offset)
        offset += Part.encode.bytes
      }
    }
    if (defined(obj.specId)) {
      buf[offset++] = 16
      encodings.enum.encode(obj.specId, buf, offset)
      offset += encodings.enum.encode.bytes
    }
    encode.bytes = offset - oldOffset
    return buf
  }

  function decode (buf, offset, end) {
    if (!offset) offset = 0
    if (!end) end = buf.length
    if (!(end <= buf.length && offset <= buf.length)) throw new Error("Decoded message is not valid")
    var oldOffset = offset
    var obj = {
      parts: [],
      specId: 0
    }
    while (true) {
      if (end <= offset) {
        decode.bytes = offset - oldOffset
        return obj
      }
      var prefix = varint.decode(buf, offset)
      offset += varint.decode.bytes
      var tag = prefix >> 3
      switch (tag) {
        case 1:
        var len = varint.decode(buf, offset)
        offset += varint.decode.bytes
        obj.parts.push(Part.decode(buf, offset, offset + len))
        offset += Part.decode.bytes
        break
        case 2:
        obj.specId = encodings.enum.decode(buf, offset)
        offset += encodings.enum.decode.bytes
        break
        default:
        offset = skip(prefix & 7, buf, offset)
      }
    }
  }
}

function defined (val) {
  return val !== null && val !== undefined && (typeof val !== 'number' || !isNaN(val))
}
