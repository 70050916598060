module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gridcraft-sandbox","short_name":"play.gridbeam.xyz","start_url":"/","background_color":"#4B2D73","theme_color":"#4B2D73","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://analytics.mikey.nz","siteUrl":"https://play.gridbeam.xyz"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
